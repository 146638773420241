<template>
  <div>
    <v-app-bar app dark color="primary">
      <v-btn icon @click="back">
        <v-icon color="white"> {{ mdiArrowLeft }} </v-icon>
      </v-btn>

      <v-toolbar-title>Find</v-toolbar-title>
    </v-app-bar>
    <div class="container mt-2 mb-3">
      <v-text-field
        :hide-details="true"
        :loading="searchLoading"
        class="mt-0 pt-0"
        label="Search"
        type="text"
        @keydown.enter="search"
        @input="searchStarted = false"
        v-model="query"
      >
        <template v-slot:append-outer>
          <v-btn v-if="!searchStarted" icon color="primary" @click="search">
            <v-icon color="primary">
              {{ mdiMagnify }}
            </v-icon>
          </v-btn>

          <v-btn v-else icon color="danger" @click="clearSearch">
            <v-icon color="primary">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <p class="subtitle-2 text--secondary mt-3 mb-1">
        Find <b>posts</b>, <b>circles</b> or <b>users</b>
      </p>

      <v-row v-if="searchLoading">
        <v-col cols="12">
          Finding...
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <!-- results -->

      <v-list
        v-else-if="
          results.circles.length > 0 ||
            results.users.length > 0 ||
            results.posts.length > 0
        "
        two-line
        subheader
      >
        <template v-if="results.circles">
          <v-subheader class="font-rubik font-weight-medium"
            >Circles ({{ results.circles.length }})</v-subheader
          >

          <v-list-item
            :to="`/c/${circle.slug}`"
            v-for="circle in results.circles"
            :key="circle.name"
          >
            <v-list-item-avatar color="orange" size="45">
              <v-avatar v-if="circle.display_picture" color="white" size="45">
                <v-img width="45" height="45" :src="circle.display_picture">
                </v-img>
              </v-avatar>

              <v-avatar
                v-else
                class="white--text text-capitalize "
                size="45px"
                color="orange"
              >
                <span class="white--text">
                  {{ circle.name.charAt(0).toUpperCase() }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ circle.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                circle.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="results.posts">
          <v-subheader class="font-rubik font-weight-medium"
            >Posts ({{ results.posts.length }})</v-subheader
          >

          <v-list-item
            :to="`/c/${post.circle}/p/${post.slug}`"
            link
            v-for="post in results.posts"
            :key="post.id"
          >
            <v-list-item-avatar
              tile
              color="transparent"
              class="body font-weight-bold"
            >
              <template v-slot:default>
                <div class="d-flex flex-column">
                  <span class="dark--text">
                    {{ (upvotes = post.upvotes + post.downvotes) }}
                  </span>
                  <span class="caption grey--text">
                    {{ upvotes > 1 ? 'upvotes' : 'upvote' }}
                  </span>
                </div>
              </template>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title> {{ post.caption }} </v-list-item-title>
              <v-list-item-subtitle class="caption"
                >{{ post.duration }} ago</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar tile>
              <v-img
                v-if="post.post_type != 'text' || post.post_type == null"
                :src="post.image"
                height="60"
                width="60"
                :lazy-src="
                  `https://picsum.photos/10/6?image=${
                    post.id ? post.id * 2 + 10 : 50
                  }`
                "
              >
              </v-img>
            </v-list-item-avatar>
          </v-list-item>
        </template>

        <template v-if="results.users">
          <v-subheader class="font-rubik font-weight-medium"
            >Users ({{ results.users.length }})</v-subheader
          >

          <v-list-item
            :to="`u/${user.username}`"
            v-for="user in results.users"
            :key="user.username"
          >
            <v-list-item-content>
              <v-list-item-title> {{ user.username }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <div
        v-else-if="
          initialSearch &&
            results.circles.length == 0 &&
            results.users.length == 0 &&
            results.posts.length == 0
        "
        class="d-flex text-center justify-center align-center"
        style="height: 300px"
      >
        <div class="text-center">
          <img class="py-3 empty-folder" src="/img/icons/empty-folder.png" />
          <span
            class="subtitle-2 d-block text-light mt-5 mb-2"
            style="width: 200px"
            >No results ye!</span
          >
          <p>
            Search for something else :p
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from '@/plugins/http';
import backer from '@/mixins/router-back';
// icons
import { mdiArrowLeft, mdiMagnify, mdiClose } from '@mdi/js';
export default {
  name: 'Find',
  mixins: [backer],
  data: () => ({
    query: '',
    initialSearch: false,
    searchStarted: false,
    searchLoading: false,
    results: {
      circles: [],
      users: [],
      posts: []
    },
    // icons
    mdiArrowLeft,
    mdiMagnify,
    mdiClose
  }),
  methods: {
    search() {
      if (this.query != '') {
        this.searchStarted = true;
        this.searchLoading = true;
        const token =
          this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
        let headers = {
          Authorization: `token ${token}`
        };

        if (!token) {
          headers = {};
        }

        // persist search query and results in store...

        http
          .get(`/v1/generalsearch/?search=${this.query}`, { headers })
          .then(response => {
            this.results = response.data;
            this.$store.dispatch('PERSIST_SEARCH', {
              query: this.query,
              results: this.results
            });
            console.log('Search completed successfully', response.data);
          })
          .catch(response => {
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Error searching Bubbl',
              style: 'error'
            });
            console.log('Error searching query ! =>', response);
          })
          .finally(() => {
            this.searchLoading = false;
            this.initialSearch = true;
          });
      } else {
        this.$store.dispatch('SHOW_TOAST', {
          message: 'Search cannot be empty :)',
          style: 'warning'
        });
      }
    },
    clearSearch() {
      this.searchLoading = false;
      this.searchStarted = false;
      this.initialSearch = false;

      this.results = {
        circles: [],
        posts: [],
        users: []
      };

      this.query = '';
    }
  },
  mounted() {
    // check if there is anything in the store...
    if (this.$store.getters.search.query) {
      console.log('Search persisted!');
      this.query = this.$store.state.search.query;
      this.results = this.$store.state.search.results;
      this.searchStarted = true;
    } else {
      console.log('Search not found :/');
    }
  },
  head: {
    title: 'Find'
  }
};
</script>
<style scoped></style>
